import SwiperCore, {
  Navigation,
  Scrollbar,
  A11y,
} from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Scrollbar, A11y]);

export const Features = (props) => {
  
  const breakpoints = {
    500: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    650: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    850: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    1200:{
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    1400:{
      slidesPerView: 5,
      slidesPerGroup: 5
    },
    1450:{
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  };

  return (
    <>

    <div id='features' className='text-center'>
      <div className="" style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
        <div className='col-md-10 section-title'>
          <h2 style={{textTransform:'none'}}>Tedavi Ettiğimiz Tanı Grupları</h2>
        </div> 

       </div>

       <div style={{display:'flex', justifyContent:'space-evenly',alignItems:'center',flexWrap:'wrap',width:'100%'}} className="mobilFeatures">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-3 col-md-3 col-xs-12 col-lg-2' style={{marginTop:80}}>
                   <i className="fa fa-fire"></i>
                  <div className='service-desc'>
                    <h3>{d.title}</h3>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>

     

    </div> 

    <div  style={{position:'relative',width:'100%',height:300, overflow:'hidden', background:'#f6f6f6' }} className="webFeatures"> 

<Swiper
  navigation
   scrollbar={{ draggable: true }} 
   class="swiper mySwiper"
   slidesPerView={6}
   slidesPerGroup={6}
   breakpoints={breakpoints}
   >
  {   props.data?.map((d, i) => (
        <SwiperSlide class="swiper-slide">
          <div key={`${d.title}-${i}`} className=''>
             <i className="fa fa-fire featuresIcon"></i>
            <div className='service-desc'>
              <h3 style={{width:'100%'}}>{d.title}</h3>
            </div>
          </div>
          </SwiperSlide>
        ))
        }
         
    </Swiper>     
 </div>
   

    </>
  )
}
