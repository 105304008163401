export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div>
        <div className='section-title'>
          <h2 style={{textTransform:'none'}}>Eğitim Verdiğimiz Tanı Grupları</h2>
        </div>
        <div style={{display:'flex', justifyContent:'space-evenly',alignItems:'center',flexWrap:'wrap',width:'100%'}}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-xs-12' style={{marginTop:10}}>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3 style={{color:'black'}}>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
