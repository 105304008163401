export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                
                <h1 style={{textTransform:'none'}}>
                       Ehil Rehabilitasyon
                </h1>

                <p style={{marginTop:50 }}>21 yılı aşkın süredir Pediatrik, Nörolojik, Ortopedik rehabilitasyon alanında Konya’dan, Türkiye’mizin diğer illerinden hatta Avrupa’dan binlerce eğitim ve tedaviye ihtiyacı olan bireye yardımcı olmanın verdiği huzurla yeni yerimizde bilgimize bilgi katarak ehil eller, donanımlı personel ile hizmet etmenin heyecanı ve gururunu yaşıyoruz.</p>
                
                <h3 style={{marginTop:10, color:'white',fontWeight:'bold',fontSize:'2em' }}>SEYFULLAH ŞEN</h3>
                <h3 style={{marginTop:5, color:'white',fontWeight:'bold',fontSize:'2em' }}>Kurucu</h3>
                <h3 style={{marginTop:5, color:'white',fontWeight:'bold',fontSize:'2em' }}>Fizik Tedavi ve Rehabilitasyon</h3>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
