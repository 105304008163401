export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <img src='img/about.jpg' className='img-responsive' alt='' style={{height:400, objectFit:'cover'}}/>
          </div>

          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>

              <h2 style={{textTransform:'none'}}>Uyguladığımız Yöntemler</h2>

              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                  <li >FİZYOTERAPİ TÜM DEĞERLENDİRME VE TEDAVİ YÖNTEMLERİ</li>
                  <li >BOBATH YÖNTEMİ</li>
                  <li >VOJTA</li>
                  <li >UGL SİSTEM TERAPİ</li>
                  <li >MANUEL TERAPİ</li>               
                  <li >MANUEL TABLE TERAPİ </li>
                  </ul>
                </div>

                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                  <li >REFLEKS TEDAVİ </li>
                  <li >PEDİATRİK NÖROLOJİK TEDAVİLER</li>
                  <li >DUYU TEST VE TEDAVİSİ</li>
                  <li >KİNEZYOTAPE</li>
                  <li >ORTEZ VE PROTEZ BELİRLENMESİ</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
