
export const Navigation = (props) => {
    return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          
        <img src="/Logo.png" style={{width:200,height:70,objectFit:'cover'}} alt="Ehil Logo"/>  

          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>

        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
           <li>
              <a href='#about' className='page-scroll'   
                style={{textTransform:'none'}} 
               >
                Uyguladığımız Yöntemler
              </a>
            </li>
            <li>
              <a href='#features' className='page-scroll' style={{textTransform:'none'}}>
                Tedavilerimiz
              </a>
            </li>
          
            <li>
              <a href='#services' className='page-scroll' style={{textTransform:'none'}}>
                Eğitim Grupları
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll' style={{textTransform:'none'}}>
                İletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
